import "./finalResult.scss";
import { MdOutlineCancel } from "react-icons/md";

import image_1 from "../../image/1.png";
import image_2 from "../../image/2.png";
import image_3 from "../../image/3.png";
import image_4 from "../../image/4.png";
import image_5 from "../../image/5.png";
import image_6 from "../../image/6.png";

const values = [
  "Sale 20K: áp dụng khi mua đơn hàng nến thơm từ 150k",
  "Quà 5K: được chọn bất kì một món đồ ăn hay nước uống có trị giá từ 5k",
  "Sale 20% Tealight: áp dụng khi mua nến thơm mini",
  "Giảm 10%: áp dụng cho đơn hàng nến thơm từ 150k",
  "Hoa mini: Đóa hoa hướng dương handmade",
  "Giảm 15k: áp dụng khi mua đơn hàng nến thơm từ 100k",
];

const InfoResult = (props) => {
  const { result, setModalFinalResult, handleSetModalButton } = props;
  return (
    <div className="final">
      <div className="blur"></div>
      <div className="content">
        <div className="group">
          {/* <p className="a">Cảm ơn bạn đã đến với TM Branding!</p> */}
          <p style={{ marginTop: "10px", color: "#062788" }}>
            Đây là phần quà của bạn:
          </p>
          <span style={{ marginTop: "10px" }}>{result}</span>
          <p style={{ marginTop: "20px" }} className="qr-code">
            {(() => {
              if (result === values[0]) {
                return <img src={image_1}></img>;
              } else if (result === values[1]) {
                return <img src={image_2}></img>;
              } else if (result === values[2]) {
                return <img src={image_3}></img>;
              } else if (result === values[3]) {
                return <img src={image_4}></img>;
              } else if (result === values[4]) {
                return <img src={image_5}></img>;
              } else if (result === values[5]) {
                return <img src={image_6}></img>;
              }
            })()}
          </p>

          <p style={{ marginTop: "20px" }}>
            Lưu ngay hình ảnh và đến Tầng 8 Tòa T để nhận quà bạn nhé! Chúng
            mình đợi bạn!
          </p>

          <div
            className="btn-off"
            onClick={() => {
              setModalFinalResult(false);
              handleSetModalButton(false);
            }}
          >
            <MdOutlineCancel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoResult;
// tat modal
