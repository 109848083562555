import { useRef, useState } from "react";
import anh2 from "../../image/anh2.jpg";

import muiten from "../../image/mui ten.png";
import vongquay from "../../image/vongquay kioko.jpg";
import quayngay from "../../image/nutquay.png";
import "./Wheel.scss";
const Wheel = (props) => {
  const { handleQuay, getResult, setModalFinal } = props;
  const [isSpinning, setSpinning] = useState(false);
  const [result, setResult] = useState(null);
  const wheelRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const values = [
    { gift: "Sale 20K: áp dụng khi mua đơn hàng nến thơm từ 150k", pct: 27 },
    {
      gift: "Quà 5K: được chọn bất kì một món đồ ăn hay nước uống có trị giá từ 5k",
      pct: 3,
    },
    { gift: "Sale 20% Tealight: áp dụng khi mua nến thơm mini", pct: 20 },
    { gift: "Giảm 10%: áp dụng cho đơn hàng nến thơm từ 150k", pct: 20 },
    { gift: "Hoa mini: Đóa hoa hướng dương handmade", pct: 3 },
    { gift: "Giảm 15k: áp dụng khi mua đơn hàng nến thơm từ 100k", pct: 27 },
  ];

  const sliceSize = 360 / 6;
  const spinWheel = () => {
    // Reset animation
    if (isSpinning) return;
    const cookieValue = localStorage.getItem("TMWheel");

    if (cookieValue === null) {
      setSpinning(true);
      const fullRots = 6
      const targetAngle = 300 * fullRots;
      const expanded = values.flatMap((user) => Array(user.pct).fill(user));
      let indexRandom = Math.floor(Math.random() * expanded.length); // arr
      const winner = expanded[indexRandom]; // get element
      let findIndexGift = values.findIndex((item) => item.gift === winner.gift);
      getResult(values[findIndexGift].gift);

      let initialRotation = 360;
      wheelRef.current.style.transition = "none";
      wheelRef.current.style.transform = `rotate(${initialRotation}deg)`;

      const randomAngle =
        Math.random() *
          ((findIndexGift + 1) * sliceSize - findIndexGift * sliceSize + 1) +
        findIndexGift * sliceSize;
      setTimeout(() => {
        wheelRef.current.style.transition = "all ease-out 5s";
        wheelRef.current.style.transform = `rotate(${
          randomAngle + targetAngle
        }deg)`;

        setTimeout(() => {
          // setResult(values[stopIndex]);
          // getResult(values[stopIndex]);
          setIsOpen(true);
          setSpinning(false);
          //handleQuay(true);
          setModalFinal(true);
          //localStorage.setItem("TMWheel", "OK");
        }, 6000);
      }, 0);
    } else {
      handleQuay(true);
      getResult("Bạn hết lượt quay !");
    }
  };

  return (
    <>
      <div className="vongquay-container">
        <img src={anh2} alt="anh" className="anh_2" />

        <div className="vongquay">
          <img src={muiten} alt="vong quay" className="arrow" />
          <img
            src={vongquay}
            alt="vong quay"
            ref={wheelRef}
            className="anh_vongquay"
          />
          <div className="btXoay" onClick={() => spinWheel()}>
            <img src={quayngay} alt="btn-quay" className="anh_btn-quay" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Wheel;
