import "./Result.scss";
import FormUser from "../form/Form";

import bgketqua from "../../image/anh2.jpg";
import { MdOutlineCancel } from "react-icons/md";

const Result = (props) => {
  const {
    handleQuay,
    result,
    hanleOffModalButton,
    handleSetModalButton,
    setModalFinalResult,
  } = props;

  return (
    <div className="modal">
      <div className="blur"></div>
      <div className="content">
        <img src={bgketqua} alt="anh" className="anh-ketqua" />

        <div
          className="btn-cancel"
          onClick={() => {
            handleQuay(false);
            hanleOffModalButton();
            handleSetModalButton(false);
          }}
        >
          <MdOutlineCancel />
        </div>
        {result === "Bạn hết lượt quay !" && (
          <>
            <div className="hetluot">
              <p>Bạn hết lượt quay !</p>
            </div>
            <p
              className="trangchu"
              onClick={() =>
                window.open("https://www.facebook.com/tmbranding?locale=vi_VN", "_blank")
              }
              style={{textAlign:"center"}}
            >
              Cơ hội việc làm tại đây
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Result;
